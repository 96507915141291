import { EncryptionSession } from "@seald-io/sdk/lib/main"
import * as Sentry from "@sentry/react"
import { useMutation, useQuery } from "@tanstack/react-query"
import { JSONContent } from "@tiptap/react"
import { AxiosResponse } from "axios"
import _ from "lodash"
import { useContext } from "react"

import {
  ConversationRequests,
  ConversationResponses,
} from "@bleu/types/endpoints/conversations"
import { SealdResponses } from "@bleu/types/endpoints/seald"
import { User, UserRole } from "@bleu/types/user"

import { checkUserAuthorization } from "@bleu/shared/utils/auth"

import { UserContext, useUser } from "@bleu/front/components/auth/UserContext"
import { apiClient } from "@bleu/front/lib/apiClient"
import { queryClient } from "@bleu/front/lib/queryClient"
import {
  createSealdIdentity,
  initiateSealdClient,
  initiateSealdIdentity,
  retrieveIdentity2MR,
  retrieveIdentityFromLocalStorage,
  retrieveSealdSessionFromConversation,
  sealdClient,
} from "@bleu/front/lib/seald"

export const useSealdIdentityQuery = () => {
  const user = useUser()
  return useQuery({
    queryKey: ["seald", user.id],
    queryFn: async () => {
      await initiateSealdClient(user.sealdDatabaseKey)
      return retrieveIdentityFromLocalStorage()
    },
  })
}

export const useSealdSignInMutation = (forceUserCreation = false) => {
  const user = useUser()

  return useMutation({
    mutationFn: async (userId?: string) => {
      // If userId is provided, we are creating a seald identity as an admin on behalf of them (doctor invite)
      let sealdId = userId ? undefined : user.sealdId
      const urlSuffix = userId ? `/${userId}` : ""

      // SIGN UP FLOW
      if (!sealdId) {
        const response = await apiClient.get<SealdResponses.SignUpToken>(
          `/seald/sign-up-token${urlSuffix}`,
        )
        const { signUpJWT } = response.data

        if (!signUpJWT) {
          Sentry.captureException(new Error("No Seald sign-up token returned"))
        }

        sealdId = await initiateSealdIdentity(signUpJWT)
      }

      // SIGN IN FLOW
      const scope = Sentry.getCurrentScope()
      scope.setTags({ send2MR: true })
      const challengeResponse =
        await apiClient.post<SealdResponses.TwoManRuleChallenge>(
          `seald/2mr-challenge${urlSuffix}`,
          {
            sealdId,
            forceUserCreation,
          },
        )
      return { tmrChallenge: challengeResponse.data, sealdId }
    },
  })
}

type SealdChallengeInput = {
  challenge: string
  twoManRuleKey: string
  twoManRuleSessionId: string
  isSignUp: boolean
}

export const useSealdChallengeMutation = () => {
  const user = useUser()
  const scope = Sentry.getCurrentScope()

  return useMutation({
    mutationFn: async ({
      challenge,
      isSignUp,
      twoManRuleSessionId,
      twoManRuleKey,
    }: SealdChallengeInput) => {
      console.log("SEALD - Challenge Mutation")
      scope.setTags({
        [isSignUp ? "createSealdIdentity" : "retrieveIdentity2MR"]: true,
      })
      scope.setExtras({ sealdIsSignUp: isSignUp })

      if (isSignUp) {
        return createSealdIdentity({
          userId: user.id,
          emailAddress: user.emailAddress,
          twoManRuleKey,
          twoManRuleSessionId,
          challenge,
        })
      } else {
        return retrieveIdentity2MR({
          userId: user.id,
          emailAddress: user.emailAddress,
          twoManRuleKey,
          twoManRuleSessionId,
          challenge,
        })
      }
    },
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries({ queryKey: ["account"] }),
        queryClient.invalidateQueries({ queryKey: ["medical-records"] }),
        queryClient.invalidateQueries({ queryKey: ["doctor", "stats"] }),
      ])
    },
  })
}

export const useSealdCompleteSignUpMutation = () => {
  const { user, updateUser } = useContext(UserContext)
  const scope = Sentry.getCurrentScope()
  return useMutation({
    mutationFn: async (sealdId: string) => {
      scope.setTags({
        sealdStep: "completeSignUp",
      })
      await apiClient.post<void>("/seald/complete-sign-up", {
        sealdId,
      })
    },
    onSuccess: async (_data, sealdId) => {
      updateUser({ ...user, sealdId })
    },
  })
}

export const useCreateConversationMutation = (
  medicalRecordId: string,
  conversationType: "notes" | "conversation",
  patient?: User,
) => {
  return useMutation({
    mutationFn: async () => {
      const sealdSession = await sealdClient.createEncryptionSession({
        sealdIds: [
          import.meta.env.VITE_SEALD_DOCTORS_GROUP_ID,
          ...(patient?.sealdId ? [patient.sealdId] : []),
        ],
      })

      let sealdRawOverEncryptionKey: string | undefined = undefined
      let sealdTwoManRuleAccessId: string | undefined = undefined
      if (patient && !patient.sealdId) {
        sealdRawOverEncryptionKey =
          await sealdClient.utils.generateB64EncodedSymKey()

        sealdTwoManRuleAccessId = await sealdSession.addTmrAccess({
          authFactor: { type: "EM", value: patient.emailAddress },
          rawOverEncryptionKey: sealdRawOverEncryptionKey,
          rights: { read: true, forward: false, revoke: false },
        })
      }

      Sentry.captureMessage("Creating conversation", {
        extra: {
          medicalRecordId,
          conversationType,
          sealdSessionId: sealdSession.sessionId,
          sealdTwoManRuleAccessId,
          patientSealdId: patient ? patient.sealdId : "NO-PATIENT",
        },
      })

      const createConversationResponse = await apiClient.post<
        ConversationResponses.Conversation,
        AxiosResponse<ConversationResponses.Conversation>,
        ConversationRequests.CreationBody
      >("/conversations", {
        medicalRecordId,
        conversationType,
        sealdSessionId: sealdSession.sessionId,
        sealdRawOverEncryptionKey,
        sealdTwoManRuleAccessId,
      })

      return {
        session: sealdSession,
        conversation: createConversationResponse.data,
      }
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["medical-records", medicalRecordId],
      })
    },
  })
}

export type DecryptedConversation = ConversationResponses.GetConversation & {
  decryptedMessages: {
    message: string | JSONContent
    authorUserId: string
    authorFirstName: string
    date: Date
  }[]
}

export type ConversationSession = {
  conversation: DecryptedConversation
  sealdSession: EncryptionSession
}

export const useConversationQuery = (conversationId: string) => {
  const user = useUser()
  const scope = Sentry.getCurrentScope()
  return useQuery<ConversationSession>({
    queryKey: ["conversations", conversationId],
    queryFn: async () => {
      const response =
        await apiClient.get<ConversationResponses.GetConversation>(
          `/conversations/${conversationId}`,
        )
      const conversation = response.data

      try {
        const sealdSession = await retrieveSealdSessionFromConversation(
          conversation,
          user.id,
          checkUserAuthorization(user, UserRole.DOCTOR, {}),
        )
        scope.setExtras({ sealdSessionId: sealdSession.sessionId })

        // Make sure every participant is in the conversation
        if (checkUserAuthorization(user, UserRole.DOCTOR, {})) {
          try {
            const conversationRecipients = await sealdSession.listRecipients()
            conversation.participants.forEach((participant) => {
              if (
                participant.sealdId &&
                !conversationRecipients.sealdUsers.find(
                  (sealdUser) => sealdUser.id === participant.sealdId,
                )
              ) {
                Sentry.captureMessage(
                  "Adding missing participant to conversation",
                  {
                    extra: { conversationId },
                    tags: { source: "conversation" },
                  },
                )
                sealdSession.addRecipients({ sealdIds: [participant.sealdId] })
              }
            })
          } catch (e) {
            console.error(e)
            Sentry.captureException(e, {
              extra: { conversationId },
              tags: { source: "conversation" },
            })
          }
        }

        const participantsById = _.keyBy(
          conversation.participants,
          (p) => p.userId,
        )

        scope.setTags({ decryptMessages: true })
        const decryptedMessages = await Promise.all(
          conversation.messages.map(async (message) => {
            const decryptedContent = await sealdSession.decryptMessage(
              message.content,
              { raw: true },
            )
            const authorParticipant = participantsById[message.authorId]

            let parsedMessage: string | JSONContent
            try {
              parsedMessage = JSON.parse(decryptedContent)
            } catch {
              parsedMessage = decryptedContent
            }

            return {
              date: new Date(message.createdAt),
              authorUserId: authorParticipant.userId,
              authorFirstName: authorParticipant.firstName,
              message: parsedMessage,
            }
          }),
        )

        // To invalidate message notification cache. Would be better to separate notifications from the rest later.
        // But for now good. TODO
        await queryClient.invalidateQueries({ queryKey: ["account"] })
        await queryClient.invalidateQueries({ queryKey: ["medical-records"] })
        await queryClient.invalidateQueries({ queryKey: ["doctor", "stats"] })

        return {
          conversation: {
            ...conversation,
            decryptedMessages,
          },
          sealdSession,
        }
      } catch (e) {
        console.error(e)
        Sentry.captureException(e, { tags: { source: "conversation" } })
        throw e
      }
    },
  })
}

export const usePostMessageMutation = (
  conversationId: string,
  sealdSession: EncryptionSession,
) => {
  return useMutation({
    mutationFn: async (message: string) => {
      const encryptedMessage = await sealdSession.encryptMessage(message, {
        raw: true,
      })

      const postMessageResponse = await apiClient.post<
        ConversationResponses.Message,
        AxiosResponse<ConversationResponses.Message>,
        ConversationRequests.PostMessageBody
      >(`/conversations/${conversationId}`, {
        encryptedMessage,
      })

      return postMessageResponse.data
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["conversations", conversationId],
      })
    },
  })
}

export const useSealdReset2MRMutation = () => {
  const user = useUser()
  const scope = Sentry.getCurrentScope()

  return useMutation({
    mutationFn: async () => {
      scope.setTags({ resetTMR: true })

      const response =
        await apiClient.post<SealdResponses.TwoManRuleChallenge>(
          "seald/reset-2mr",
        )

      return response.data
    },
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries({ queryKey: ["seald", user.id] }),
        queryClient.invalidateQueries({ queryKey: ["account"] }),
      ])
    },
  })
}
