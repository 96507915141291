import * as Sentry from "@sentry/react"
import { useEffect } from "react"
import { Outlet, useLocation } from "react-router-dom"

import { useUTMStore } from "@bleu/front/lib/stores/utmStore"
import { useAuthState } from "@bleu/front/queries/auth"
import { pageview } from "@bleu/front/utils/tracking"

export const TrackingWrapper = () => {
  const { user } = useAuthState()
  const location = useLocation()
  const setUTMParams = useUTMStore((state) => state.setUTMParams)

  // Identify user through Amplitude & Sentry
  useEffect(() => {
    if (user?.id) {
      Sentry.setUser({
        id: user.id,
        clerkId: user.clerkId,
      })
    }
  }, [user?.clerkId, user?.id])

  // Track page view
  useEffect(() => {
    pageview(location.pathname + location.search)
  }, [location.pathname, location.search])

  // Capture UTM parameters
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const utmSource = searchParams.get("utm_source") || null
    const utmMedium = searchParams.get("utm_medium") || null
    const utmCampaign = searchParams.get("utm_campaign") || null
    const utmContent = searchParams.get("utm_content") || null

    const hasNonEmptyUTM = [utmSource, utmMedium, utmCampaign, utmContent].some(
      (param) => param !== null && param !== "",
    )

    if (hasNonEmptyUTM) {
      setUTMParams({ utmSource, utmMedium, utmCampaign, utmContent })
    }
  }, [location.search, setUTMParams])

  return <Outlet />
}
