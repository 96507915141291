import _ from "lodash"

import { Prisma } from "@bleu/types/prisma-client"

import { PrescriptionType } from "@bleu/shared/prescriptions/types"

import { MedicalRecordStatuses } from "./statuses"

type ComputeStatBlock = {
  key: string
  label: string
  compute: (
    medicalRecords: Prisma.MedicalRecordGetPayload<{
      include: {
        conversation: { include: { messages: true } }
        documents: true
      }
    }>[],
    doctorUserId: string,
  ) => number
}

export const DOCTOR_STAT_BLOCKS: ComputeStatBlock[] = [
  {
    key: "testPrescriptionsCount",
    label: "Nombre d'ordonnances dépistage validées",
    compute: (records) =>
      records.flatMap((r) =>
        r.documents.filter(
          (doc) => doc.type !== PrescriptionType.PRESCRIPTION_TREATMENT,
        ),
      ).length,
  },
  {
    key: "treatmentPrescriptionsCount",
    label: "Nombre d'ordonnances traitements délivrées",
    compute: (records) =>
      records.flatMap((r) =>
        r.documents.filter(
          (doc) => doc.type === PrescriptionType.PRESCRIPTION_TREATMENT,
        ),
      ).length,
  },
  {
    key: "messagesCount",
    label: "Nombre de messages adressés",
    compute: (records, doctorUserId) =>
      records.flatMap(
        (r) =>
          r.conversation?.messages.filter((m) => m.authorId === doctorUserId) ??
          [],
      ).length,
  },
  {
    key: "patientsCount",
    label: "Nombre de patients individuels",
    compute: (records) => _.uniqBy(records, (r) => r.patientId).length,
  },
]

type DoctorMedicalRecordStatusFilter = {
  key: string
  label: string
  filter: (doctorUserId: string) => Prisma.MedicalRecordWhereInput
}

export const DOCTOR_STATUS_FILTERS: DoctorMedicalRecordStatusFilter[] = [
  {
    key: "pendingPrescription",
    label: "Prescriptions en attente",
    filter: () => ({ status: MedicalRecordStatuses.PENDING_PRESCRIPTION }),
  },
  {
    key: "pendingResults",
    label: "En attente de résultats patients",
    filter: () => ({
      status: MedicalRecordStatuses.PENDING_LAB_RESULTS,
    }),
  },
  {
    key: "pendingAnalysis",
    label: "Résultats en attente de commentaires",
    filter: () => ({
      status: MedicalRecordStatuses.PENDING_LAB_RESULTS_ANALYSIS,
    }),
  },
  {
    key: "pendingAnswer",
    label: "Réponses aux patients en attente",
    filter: () => ({
      status: { not: MedicalRecordStatuses.CLOSED },
    }),
  },
  {
    key: "pendingClosing",
    label: "En attente de clotûre",
    filter: () => ({
      status: MedicalRecordStatuses.LAB_RESULTS_ANALYZED,
    }),
  },
  {
    key: "closed",
    label: "Clotûrés",
    filter: () => ({
      status: MedicalRecordStatuses.CLOSED,
    }),
  },
  {
    key: "all",
    label: "Tous",
    filter: () => ({
      status: { not: MedicalRecordStatuses.PENDING_PAYMENT },
    }),
  },
  // {
  //   key: "pendingPayment",
  //   label: "Gratuité",
  //   filter: (doctorId: string) => ({
  //     OR: [
  //       {
  //         status: MedicalRecordStatuses.PENDING_PAYMENT,
  //         isFreemium: true,
  //         ownerDoctor: { userId: doctorId },
  //       },
  //       {
  //         status: MedicalRecordStatuses.PENDING_PAYMENT,
  //         isFreemium: true,
  //       },
  //     ],
  //   }),
  // },
] as const
