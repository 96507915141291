import { create } from "zustand"
import { persist } from "zustand/middleware"

interface UTMState {
  utmSource: string | null
  utmMedium: string | null
  utmCampaign: string | null
  utmContent: string | null
  setUTMParams: (params: Partial<UTMState>) => void
  clearUTMParams: () => void
}

type UTMKeys = "utmSource" | "utmMedium" | "utmCampaign" | "utmContent"

export const useUTMStore = create<UTMState>()(
  persist(
    (set, get) => ({
      utmSource: null,
      utmMedium: null,
      utmCampaign: null,
      utmContent: null,
      setUTMParams: (params) => {
        const currentState = get()
        const newState = Object.entries(params).reduce(
          (acc, [key, value]) => {
            if (key in currentState && typeof value !== "function") {
              acc[key as UTMKeys] = value
            }
            return acc
          },
          {} as Partial<Pick<UTMState, UTMKeys>>,
        )

        set(newState)
      },
      clearUTMParams: () =>
        set({
          utmSource: null,
          utmMedium: null,
          utmCampaign: null,
          utmContent: null,
        }),
    }),
    {
      name: "utm-storage",
      skipHydration: false,
      partialize: (state) =>
        Object.fromEntries(
          Object.entries(state).filter(
            ([key, value]) =>
              !["setUTMParams", "clearUTMParams"].includes(key) &&
              value !== null,
          ),
        ),
    },
  ),
)
