import { ComponentType, memo } from "react"

import { QueryWrapper } from "@bleu/front/components/layout/QueryWrapper"
import {
  SealdProvider,
  SealdSignInComponentProps,
} from "@bleu/front/components/seald/SealdProvider"
import {
  ConversationSession,
  useConversationQuery,
} from "@bleu/front/queries/seald"

type ConversationComponent = {
  children: (props: ConversationSession) => React.JSX.Element
}

type ConversationQueryWrapperProps = ConversationComponent & {
  conversationId: string
}

const ConversationQueryWrapper = ({
  children,
  conversationId,
}: ConversationQueryWrapperProps) => {
  const conversationQuery = useConversationQuery(conversationId)

  return (
    <QueryWrapper query={conversationQuery}>
      {({ data: { conversation, sealdSession } }) =>
        children({ conversation, sealdSession })
      }
    </QueryWrapper>
  )
}

type ConversationProviderProps = {
  conversationId: string
  signInComponent?: ComponentType<SealdSignInComponentProps>
} & ConversationComponent

export const ConversationProvider = memo(
  ({
    conversationId,
    children,
    signInComponent,
  }: ConversationProviderProps) => {
    return (
      <SealdProvider signInComponent={signInComponent}>
        <ConversationQueryWrapper conversationId={conversationId}>
          {children}
        </ConversationQueryWrapper>
      </SealdProvider>
    )
  },
)

ConversationProvider.displayName = "ConversationProvider"
